
import { computed, reactive, watch, ref, defineComponent, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import { imageUrl } from '@/utils/image'
import { formatTime } from '@/utils/dateTime'
import Pagination from '@/components/common/Pagination.vue'
import CustomerDetailDialog from '@/components/common/CustomerDetailDialog.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
    CustomerDetailDialog,
  },
  setup() {
    const breadcrumbs = ['Customers', '']
    const title = 'Customers'
    const baseResource = 'orders/customers/search'

    const content = computed(() => {
      return { breadcrumbs, title }
    })

    const onFilterCallback = (filter: any) => {
      params.search = filter.search
    }

    let loading = ref(false)

    const params = reactive({
      search: null,
      country: null,
      sellerTeam: null,
      page: 1,
      limit: 10,
    })
    const data = reactive({
      sellerTeams: ref<any[]>([]),
    })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )

    onMounted(async () => {
      loading = isLoading
      await fetchData()
    })

    watch(() => [params.limit, params.page], fetchData)

    const dialogVisible = ref<boolean>(false)
    const customerDetailDialog =
      ref<InstanceType<typeof CustomerDetailDialog>>()
    const onViewDetailCustomer = (email: string) => {
      customerDetailDialog.value?.toggle(email)
    }

    const columns = [
      {
        key: '_id',
        title: 'Full Name',
        dataIndex: 'fullName',
        minWidth: '10',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        minWidth: '15',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        minWidth: '20',
      },
      {
        title: 'City',
        dataIndex: 'city',
        minWidth: '10',
      },
      {
        title: 'Province',
        dataIndex: 'province',
        minWidth: '8',
      },
      {
        title: 'Country',
        dataIndex: 'country',
        minWidth: '10',
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: '10',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        minWidth: '15',
        align: 'center',
      },
    ]

    return {
      params,
      content,
      documents,
      isLoading,
      loading,
      total,
      columns,
      imageUrl,
      formatTime,
      onFilterCallback,
      fetchData,
      data,
      onViewDetailCustomer,
      customerDetailDialog,
      dialogVisible,
    }
  },
})
